import React from "react";
import DeliveryIcon from "./icons/Delivery.svg";
import CarryoutIcon from "./icons/Carryout.svg";
import PizzaCarryoutIcon from "./icons/PizzaCarryout.svg";
import CateringIcon from "./icons/Catering.svg";
import CateringDeliveryIcon from "./icons/CateringDelivery.svg";
import SitdownIcon from "./icons/Sitdown.svg";
import DropOffIcon from "./icons/DropOff.svg";
import CurbsideIcon from "./icons/Curbside.svg";

export default function ServingOptionsIcon({ appStyles, servingOption }) {
  return servingOptionIcons(appStyles.isPizza)[servingOption];
}

const servingOptionIcons = (pizza) => ({
  pickup: pizza ? <PizzaCarryoutIcon /> : <CarryoutIcon />,
  delivery: <DeliveryIcon />,
  sitdown: <SitdownIcon />,
  catering: <CateringIcon />,
  cateringDelivery: <CateringDeliveryIcon />,
  dropoff: <DropOffIcon />,
  curbside: <CurbsideIcon />,
});
